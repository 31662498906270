<template>
  <article class="post-item">
    <a :href="post.acf.post_external ? post.acf.post_external : props.post.link" :title="props.post.post_title"
      :target="post.acf.post_external ? '_blank' : ''">
      <div class="post-info">
        <time>{{ formatDate(props.post.date) }}</time>
        <div class="source" v-if="post.acf.post_source">{{ post.acf.post_source }}</div>
      </div>
      <p class="title" v-html="props.post.title.rendered"></p>
      <p class="btn small">Read Article</p>
    </a>
  </article>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import PostTaxonomies from '@/components/utility/PostTaxonomies.vue'

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})

const formatDate = (postDate) => {
  const date = new Date(postDate)

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  return new Intl.DateTimeFormat("en-US", dateOptions).format(date)
}

onMounted(() => {
  // const date = new Date(props.post.date)
  // let options = {
  //   year: "numeric",
  //   month: "short",
  // }
  // console.log(new Intl.DateTimeFormat("en-DE", options).format(date));
})
</script>

<style lang="scss">
.post-item {
  width: 40%;
  margin: 0 0 50px;

  @include mobile {
    width: 100%;
  }

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .post-info {
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    .source {
      display: flex;
      align-items: center;
      @include font-main-medium;

      &:before {
        content: '|';
        display: block;
        margin: 0 5px;
      }
    }
  }

  .title {
    margin: 20px 0;
  }

  .btn {
    align-self: flex-start;
    margin: auto 0 0;
  }
}
</style>
