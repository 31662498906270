<template>
  <main>
    <section class="news">
      <div
        v-if="categories.length && activeFilter"
        class="post-categories">
        <div class="container">
          <div class="categories">
            <div
              v-for="category in categories"
              :key="category.id"
              class="category"
              :class="{ active: activeFilter.id == category.id }"
              @click="handleFilter(category)">
              {{ category.name }}
            </div>
          </div>
          <div
            v-if="categories.length && activeFilter"
            class="category-dropdown"
            data-lenis-prevent>
            <div
              class="category-btn"
              :class="{ open: isOpen }"
              @click="open">
              <div class="name">
                {{ activeFilter.name }}
              </div>
            </div>
            <Vue3SlideUpDown v-model="isOpen">
              <ul>
                <li
                  v-for="category in categories"
                  :key="category.id"
                  class="category"
                  :class="{ active: activeFilter.id == category.id }"
                  @click="handleFilter(category)">
                  {{ category.name }}
                </li>
              </ul>
            </Vue3SlideUpDown>
          </div>
        </div>
      </div>
      <div class="posts">
        <div class="container large">
          <div
            class="posts-container"
            :class="{ loading: loading }">
            <transition-group name="posts">
              <PostItem
                v-for="post in posts"
                :key="post.id"
                :post="post" />
            </transition-group>
          </div>
          <div
            v-if="site.media_relations"
            class="relations-contact">
            <a
              class="font-smaller"
              :href="`mailto:${site.media_relations}`">
              <p>Media Relations: {{ site.media_relations }}</p>
              <div class="arrow"></div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import PostItem from '@/components/template-parts/PostItem.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from '../stores/main'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const store = useStore()
const site = ref(store.acfOptions())
const loading = ref(true)
const isOpen = ref(false)

const open = () => {
  isOpen.value = !isOpen.value
}

const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
})

const pageRequest = ref({
  type: 'pages',
  slug: props.slug,
  showLoading: true,
})

const categoryRequest = ref({
  type: 'categories',
  params: {
    orderby: 'id',
  },
})
const activeFilter = ref()
const pageFeaturedImage = ref()

const posts = ref('')
const categories = computed(() => {
  return store.requestedItems(categoryRequest.value)
})
const postsPage = computed(() => {
  return store.singleBySlug(pageRequest.value)
})

const getCategories = () => {
  return store.getItems(categoryRequest.value)
}

const getPostsPage = () => {
  store.getSingleBySlug(pageRequest.value).then(() => {
    const imgRequest = {
      type: 'media',
      id: postsPage.value.featured_media,
      params: {
        _fields: 'alt_text,id,source_url',
      },
    }

    store.getSingleById(imgRequest).then(() => {
      pageFeaturedImage.value = store.singleById(imgRequest)
    })
  })
}

const handleFilter = (category) => {
  isOpen.value = false
  activeFilter.value = category

  const request = ref({
    type: 'posts',
    params: {
      categories: category.id,
      fields:
        'id,slug,title,featured_media,link,categories,excerpt,date,modified,content',
      per_page: 100,
    },
    showLoading: false,
  })

  store.getItems(request.value).then(() => {
    posts.value = store.requestedItems(request.value)
    loading.value = false
  })
}

onBeforeMount(() => {
  store.updateDocTitle({
    parts: ['Publications & Press', store.site.name],
  })

  getPostsPage()

  getCategories().then(() => {
    const category = categories.value[0]
    handleFilter(category)
  })
})
</script>

<style lang="scss">
.news {
}
</style>
