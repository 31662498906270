<template>
  <div class="banner" v-if="site.banner_text || site.banner_link">
    <div class="container">
      <div class="banner-content">
        <div class="text text-smaller">{{ site.banner_text }}</div>
        <a :href="site.banner_link.url" :target="site.banner_link.target" class="btn small">{{ site.banner_link.title }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
import { ref } from 'vue'

const store = useStore()
const site = ref(store.acfOptions())
</script>

<style lang="scss">
.banner {
  padding: 18px 0;
  color: $gray;
  background-color: $green;

  .banner-content {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    .text {
      margin-right: 40px;

      @include mobile {
        text-align: center;
        margin: 0 0 20px;
      }
    }
  }
}
</style>
