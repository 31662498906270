// Route components
import Page from '@/components/PageMaster.vue'
import Posts from '@/components/PostsComponent.vue'
import NotFound from '@/components/404Page.vue'
import AuthorArchive from '@/components/AuthorArchive.vue'
import DateArchive from '@/components/DateArchive.vue'
import CategoryArchive from '@/components/CategoryArchive.vue'
import TagArchive from '@/components/TagArchive.vue'
import Single from '@/components/SingleComponent.vue'
import SingleTeam from '@/components/SingleTeam.vue'
// Route paths as formatted in WP permalink settings
import paths from './paths'
// Route composition utilities
import { categorySlugFromParams, pageFromPath } from './utils'

const { show_on_front, page_for_posts, page_on_front } =
  window.__VUE_WORDPRESS__.routing

const postsPageRoute =
  show_on_front === 'page' && page_for_posts
    ? {
        path: paths.postsPage(page_for_posts),
        component: Posts,
        name: 'Posts',
        meta: { title: `${document.title}` },
        props: (route) => ({
          page: pageFromPath(route.path),
          slug: page_for_posts,
        }),
      }
    : null

const rootRoute =
  show_on_front === 'page' && page_on_front
    ? {
        path: '/',
        component: Page,
        name: 'Home',
        meta: { bodyClass: 'v-home', title: `${document.title}` },
        props: () => ({
          slug: page_on_front,
          isHomepage: true,
          showIntro: true,
        }),
      }
    : null

const routes = [
  rootRoute,
  postsPageRoute,
  {
    path: '/404',
    component: NotFound,
    name: '404',
    meta: { title: `${document.title}` },
  },
  {
    path: paths.authorArchive,
    component: AuthorArchive,
    name: 'AuthorArchive',
    meta: { title: `${document.title}` },
    props: (route) =>
      Object.assign(route.params, { page: pageFromPath(route.path) }),
  },
  {
    path: paths.dateArchive,
    component: DateArchive,
    name: 'DateArchive',
    meta: { title: `${document.title}` },
    props: (route) =>
      Object.assign(route.params, { page: pageFromPath(route.path) }),
  },
  {
    path: paths.categoryArchive,
    component: CategoryArchive,
    name: 'CategoryArchive',
    meta: { title: `${document.title}` },
    props: (route) =>
      Object.assign(route.params, {
        slug: categorySlugFromParams(route.params),
        page: pageFromPath(route.path),
      }),
  },
  {
    path: paths.tagArchive,
    component: TagArchive,
    name: 'TagArchive',
    meta: { title: `${document.title}` },
    props: (route) =>
      Object.assign(route.params, { page: pageFromPath(route.path) }),
  },
  {
    path: '/team/:slug',
    component: SingleTeam,
    name: 'SingleTeam',
    meta: { title: `${document.title}` },
    props: (route) => ({ slug: route.params.slug }),
  },
  {
    path: paths.single,
    component: Single,
    name: 'Single',
    meta: { title: `${document.title}` },
    props: (route) => ({ slug: route.params.slug, newsSlug: page_for_posts }),
  },
  {
    path: '/:slugs+',
    component: Page,
    name: 'Page',
    meta: { title: `${document.title}` },
    props: (route) => ({
      slug: route.params.slugs[0]
        .split('/')
        .filter((s) => s)
        .pop(),
    }),
  },
].filter((route) => route) // Removes empty route objects

export default routes
