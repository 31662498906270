<template>
  <div class="team-section">
    <div class="team-category text-larger" v-html="data.team_category_name"></div>
    <div class="team-members-container">
      <template v-if="team.length > 0">
        <div v-for="member in team" :key="member.id" class="team-member">
          <a :href="member.acf.team_member_external_link
        ? member.acf.team_member_external_link
        : member.link
      " :target="member.acf.team_member_external_link ? '_blank' : ''">
            <div class="image-container">
              <ResponsiveImage v-if="member.featured_media" :media-id="member.featured_media" />
              <div v-else class="image-placeholder"></div>
            </div>
            <div class="member-info">
              <div class="name" v-html="member.title.rendered"></div>
              <div class="title" v-html="member.acf.team_member_title"></div>
            </div>
            <div class="btn small">View Bio</div>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-for="(item, index) in data.team_list" :key="index" class="team-placeholder">
          <div class="placeholder-inner"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
import { ref, onMounted, computed } from 'vue'
import ResponsiveImage from '@/components/utility/ResponsiveImage.vue'

const store = useStore()
const props = defineProps(['data'])
const loading = ref(true)

const request = {
  type: 'team',
  params: {
    include: props.data.team_list,
    orderby: 'include',
    _fields: 'id,slug,link,title,featured_media,excerpt,acf,content',
    per_page: 100,
  },
  showLoading: false,
}

const team = computed(() => {
  return store.requestedItems(request)
})

const getTeam = () => {
  store.getItems(request).then(() => {
    loading.value = false
  })
}

onMounted(() => {
  getTeam()
})
</script>

<style lang="scss">
.team-section {
  margin: 0 0 40px;

  .team-members-container {
    display: flex;
    flex-wrap: wrap;
  }

  .team-member {
    position: relative;
    width: 20%;
    margin-right: 6.66%;
    margin-bottom: 50px;

    @include mobile {
      width: 48%;
      margin-right: 4%;
    }

    &:nth-child(4n) {
      margin-right: 0;

      @include mobile {
        margin-right: 4%;
      }
    }

    &:nth-child(2n) {
      
      @include mobile {
        margin-right: 0;
      }
    }

    a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .image-container {
      position: relative;
      width: 100%;
      padding-bottom: 100%;

      .responsive-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }

    .member-info {
      font-size: 1.4rem;
      margin: 30px 0;

      .name {
        margin: 0 0 10px;
        @include font-main-medium;
      }
    }

    .btn {
      align-self: flex-start;
      margin: auto 0 0;
    }
  }
}
</style>
