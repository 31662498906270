<template>
  <header class="header-nav" :class="`${navOpen ? 'nav-open' : ''}`">
    <nav class="nav-container">
      <div class="nav-outer-container">
        <div class="logo" @click="close">
          <a href="/">
            <img src="@/assets/img/logo-mareatx.svg" alt="Mareatx logo" />
          </a>
        </div>
        <div class="nav-wrapper" :class="`${navOpen ? 'nav-open' : ''}`">
          <ul ref="nav">
            <template v-for="item in menu" :key="item.id">
              <li v-if="item.parent === '0'">
                <template v-if="isExternal(item.url)">
                  <a @click="close" :href="item.url" :target="item.target" class="no-router" v-html="item.content"></a>
                </template>
                <template v-else>
                  <router-link @click="close" :to="convertToRelative(item.url)" exact v-html="item.content">
                  </router-link>
                </template>
              </li>
            </template>
          </ul>
        </div>
        <div class="hamburger" @click="open" :class="`${navOpen ? 'nav-open' : ''}`">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from '@/stores/main'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import useUtils from '@/components/composables/useUtils.js'

const { convertToRelative, isExternal } = useUtils()

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const header = ref(null)
const nav = ref(null)
const navOpen = ref(false)

const { locoScroll } = useSmoothScroll()

const open = () => {
  if (navOpen.value) {
    navOpen.value = false
    locoScroll.value.start()
  } else {
    navOpen.value = true
    locoScroll.value.stop()
  }
}

const close = () => {
  navOpen.value = false
  locoScroll.value.start()
}
</script>

<style lang="scss">
.header-nav {
  background-color: $white;

  .nav-container {
    padding: 42px 0;
    margin: auto;
    width: 93%;
    max-width: $max-width;

    @include tablet-landscape(1) {
      padding: 30px 0;
    }

    .nav-outer-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        position: relative;
        z-index: 11;
      
        img {
          width: 325px;

          @include tablet-landscape(1) {
            width: 150px;
          }
        }
      }

      .nav-wrapper {

        @include tablet-landscape(1) {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          z-index: -1;
          pointer-events: none;
          background-color: $white;
          @include transition;
        }

        &.nav-open {
          opacity: 1;
          display: block;
          pointer-events: all;
          z-index: 10;
        }

        ul {
          display: flex;

          @include tablet-landscape(1) {
            padding: 100px 0;
            flex-direction: column;
            align-items: center;
          }

          li {
            margin-right: 50px;

            @include tablet-landscape(1) {
              margin: 0 0 30px;
            }

            &:last-child {
              margin: 0;
            }

            a {
              font-size: 2.4rem;
            }
          }
        }
      }

      .hamburger {
        cursor: pointer;
        position: relative;
        width: 30px;
        display: none;
        align-items: center;
        justify-content: flex-end;
        z-index: 11;

        @include tablet-landscape(1) {
          display: block;
        }

        &.nav-open {
          span {
            &:first-child {
              transform: rotate(45deg);
              top: 10px;
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:last-child {
              transform: rotate(-45deg);
              top: -10px;
            }
          }
        }

        .nav-locations {
          margin-right: 20px;
        }

        span {
          position: relative;
          display: block;
          width: 100%;
          height: 3px;
          top: 0;
          text-indent: -9999px;
          background-color: $green;
          @include transition;

          &:nth-child(2) {
            margin: 7px 0;
          }
        }
      }
    }
  }
}
</style>
