<template>
  <nav>
    <ul>
      <li :class="weight" v-for="item in menu" :key="item.id">
        <template v-if="isExternal(item.url)">
          <a
            :href="item.url"
            :target="item.target"
            class="no-router"
            :tabindex="active ? 0 : -1"
            v-html="item.content"
          ></a>
        </template>
        <template v-else>
          <router-link
            :to="convertToRelative(item.url)"
            exact
            v-html="item.content">
          </router-link>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/components/composables/useUtils.js'

const store = useStore()
const props = defineProps(['name', 'weight'])
const menu = ref(store.menu({ name: props.name }))
const { convertToRelative, isExternal } = useUtils()

</script>

<style lang="scss"></style>
