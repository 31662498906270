<template>
  <div class="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-if="page.template === 'page-legal.php'">
          <div class="page-legal">
            <div class="container med editor-formatting">
              <h1>{{ page.title.rendered }}</h1>
              <div class="legal-content" v-html="page.content.rendered"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-for="(module, index) in page.acf.modules">
            <template v-if="module.acf_fc_layout === 'ImgTextComponent'">
              <ImgTextComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'PipelineComponent'">
              <PipelineComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'TeamComponent'">
              <TeamComponent :key="index" :data="module" :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'NewsComponent'">
              <NewsComponent :key="index" :data="module" :index="index" />
            </template>
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script setup>
import { computed, inject, onBeforeMount, onMounted, nextTick } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import useUtils from '@/components/composables/useUtils.js'

import ImgTextComponent from '@/components/modules/ImgTextComponent.vue'
import PipelineComponent from '@/components/modules/PipelineComponent.vue'
import TeamComponent from '@/components/modules/Team/TeamComponent.vue'
import NewsComponent from '@/components/modules/NewsComponent.vue'

const store = useStore()
const router = useRouter()
const props = defineProps(['slug', 'isHomepage'])
const request = {
  type: 'pages',
  slug: props.slug,
  showLoading: true,
}

const { decodeTitle } = useUtils()

const navState = inject('navState')

const page = computed(() => {
  return store.getCurrentSingleBySlug(request)
})

onBeforeMount(() => getPage())

const getPage = async () => {
  store.getSingleBySlug(request).then(() => {
    if (page.value) {
      if (!props.isHomepage) {
        store.updateDocTitle({
          parts: [decodeTitle(page.value.title.rendered), store.site.name],
        })
      } else {
        navState.value.class = ''
        store.updateDocTitle({
          parts: [null, store.site.name],
        })
      }
    } else {
      router.replace('/404')
    }
  })
}
</script>

<style lang="scss">
.page-legal {
  padding: 100px 0;

  @include mobile {
    padding: 60px 0;
  }
}
</style>
