<template>
  <div class="wrapper">
    <main class="error-404">
      <div class="container">
        <h1>Page Not Found</h1>
        <div class="text font-larger">
          <p>Error 404</p>
          <p>Sorry, we cannot find what you're looking for.</p>
        </div>
        <a
          href="/"
          class="btn"
          >Go to Homepage</a
        >
      </div>
    </main>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'

const store = useStore()

store.updateDocTitle({
  parts: ['Page not found', store.site.name],
})
</script>

<style lang="scss">
.error-404 {
  padding: 150px 0 100px;

  h1 {
    margin: 0 0 20px;
  }

  .text {
    margin: 0 0 40px;
  }
}
</style>
