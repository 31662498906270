<template>
  <section class="news" id="news">
    <div class="container">
      <div class="news-head">
        <div class="headline h2">News</div>
      </div>
      <div class="posts">
        <template v-if="posts">
          <PostItem v-for="post in posts" :key="post.id" :post="post" />
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "NewsComponent",
    "CLASSNAME": "news",
    "URL": "http://local.mareatx.com/"
   }
  #ENDMETA */
import PostItem from '@/components/template-parts/PostItem.vue'
import { useStore } from '@/stores/main'
import { ref, computed, onBeforeUnmount, onMounted } from 'vue'

const store = useStore()

defineProps(['data'])

const postRequest = {
  type: 'posts',
  params: {
    per_page: 100,
    _fields:
      'id,slug,title,featured_media,link,author,categories,excerpt,date,modified,content,acf',
  },
  showLoading: false,
}

const posts = computed(() => {
  return store.requestedItems(postRequest)
})

const getPosts = () => {
  store.getItems(postRequest)
}

onMounted(() => {
  getPosts()
})

onBeforeUnmount(() => { })
</script>

<style lang="scss">
.news {
  padding: 60px 0;

  .news-head {
    margin: 0 0 50px;

    .headline {
      @include font-main-bold;
  
      &:after {
        content: '';
        display: block;
        width: 150px;
        height: 10px;
        margin: 10px 0;
        border-radius: 20px;
        background: linear-gradient(90deg, rgba(244, 234, 120, 0.47) 0%, rgba(89, 206, 200, 0.37) 95.96%);
      }
    }
  }

  .posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
