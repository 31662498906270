<template>
  <div class="wrapper">
    <main class="single-post">
      <div class="container med">
        <div class="btn-container">
          <a href="/news" class="btn-gradient">Back to News</a>
        </div>
        <template v-if="post">
          <article>
            <div class="lines left">
              <span></span>
            </div>
            <div class="post-title">
              <h1 class="title h3" v-html="post.title.rendered"></h1>
            </div>
            <div v-if="post.featured_image" class="featured-image mt-10">
              <img :src="post.featured_image.large" :alt="post.featured_image.alt" />
            </div>
            <div class="post-content">
              <template v-if="post.acf.post_external">
                <a :href="post.acf.post_external">Read More</a>
              </template>
              <template v-else>
                <div class="editor-formatting" v-html="post.content.rendered"></div>
              </template>
            </div>
          </article>
        </template>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '@/stores/main'
// import { format } from 'date-fns'
import useUtils from '@/components/composables/useUtils.js'

const { decodeTitle } = useUtils()

const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
})

const request = {
  type: 'posts',
  slug: props.slug,
  showLoading: true,
}

const date = ref('')
const post = computed(() => {
  return store.singleBySlug(request)
})

const getPost = async () => {
  store.getSingleBySlug(request)
  if (post.value) {
    store.updateDocTitle({
      parts: [decodeTitle(post.value.title.rendered), store.site.name],
    })

    // date.value = format(new Date(post.value.date), 'MM/d/yyyy')
  }
}

onBeforeMount(() => getPost())
</script>

<style lang="scss">
.single-post {
  
}
</style>
