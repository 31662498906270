<template>
  <main class="single-team">
    <div class="container small">
      <template v-if="member">
        <div class="member-container">
          <div class="photo-container">
            <ResponsiveImage v-if="member.featured_media" :native="true" :media-id="member.featured_media" />
          </div>
          <div class="info-container">
            <div class="info-head">
              <h1 class="name h3" v-html="member.title.rendered"></h1>
              <div v-if="member.acf.team_member_title" class="member-title text-larger" v-html="member.acf.team_member_title">
              </div>
            </div>
            <div v-if="member && member.content" class="member-bio editor-formatting" v-html="member.content.rendered">
            </div>
          </div>
        </div>
      </template>
    </div>
  </main>
</template>

<script setup>
import { computed, ref, inject, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../stores/main'
import useUtils from '@/components/composables/useUtils.js'
import ResponsiveImage from '@/components/utility/ResponsiveImage.vue'

const router = useRouter()
const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
    default: '',
  },
})

const { decodeTitle } = useUtils()

const request = ref({
  type: 'team',
  slug: props.slug,
  showLoading: true,
})

const member = computed(() => {
  return store.getCurrentSingleBySlug(request.value)
})

const getMember = async () => {
  await store.getSingleBySlug(request.value)
  if (member.value) {
    store.updateDocTitle({
      parts: [decodeTitle(member.value.title.rendered), store.site.name],
    })
  } else {
    router.replace('/404')
  }
}

onBeforeMount(async () => {
  await getMember()
})
</script>

<style lang="scss">
.single-team {
  padding: 100px 0;

  @include mobile {
    padding: 60px 0;
  }

  .member-container {
    display: flex;

    .photo-container {
      margin-right: 40px;
    }

    .info-container {
      flex: 1;

      .info-head {
        margin: 0 0 40px;

        .name {
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 10px;
            margin: 5px 0;
            border-radius: 20px;
            background: linear-gradient(270deg, rgba(244, 234, 120, 0.47) 0%, rgba(89, 206, 200, 0.37) 95.96%);
          }
        }
      }
    }
  }
}
</style>
