<template>
  <footer v-show="!store.site.loading && site" class="site-footer">
    <div class="container">
      <div class="footer-head">
        <div class="footer-logo-socials">
          <div class="footer-logo">
            <router-link :to="'/'" title="Home">
              <img src="@/assets/img/logo-mareatx.svg" alt="Mareatx Logomark" class="logo" />
            </router-link>
          </div>
          <div class="footer-info text-smaller editor-formatting mobile" v-if="site.footer_info" v-html="site.footer_info">
          </div>
          <div class="socials">
            <a v-if="site.social_linkedin" :href="site.social_linkedin" target="_blank" class="social linkedin">
              <img src="@/assets/img/logo-linkedin.svg" alt="LinkedIn Logo" />
            </a>
            <a v-if="site.social_twitter" :href="site.social_twitter" target="_blank" class="social twitter">
              <img src="@/assets/img/logo-x.png" alt="X Logo" />
            </a>
          </div>
        </div>
        <div class="footer-info text-smaller editor-formatting" v-if="site.footer_info" v-html="site.footer_info"></div>
      </div>
      <div class="footer-legal">
        <div class="copyright">
          <p>Mareatx Therapeutics &copy;{{ currentYear }}</p>
        </div>
        <div class="legal-menu">
          <FooterMenu name="legal-menu" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed } from 'vue'

const store = useStore()
const site = ref(store.acfOptions())

const currentYear = computed(() => {
  return new Date().getFullYear()
})
</script>

<style lang="scss">
.site-footer {
  padding: 40px 0;

  .footer-head {
    display: flex;
    justify-content: space-between;

    @include mobile {
      align-items: center;
      flex-direction: column;
    }

    .footer-logo-socials {

      .footer-logo {
        margin: 0 0 40px;

        img {
          width: 250px;
        }
      }

      .socials {
        display: flex;

        @include mobile {
          justify-content: center;
        }

        a {
          margin-right: 30px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .footer-info {

    &.mobile {
      display: none;

      @include mobile {
        display: block;
        text-align: center;
        margin:  0 0 40px;
      }
    }

    &:not(.mobile) {

      @include mobile {
        display: none;
      }
    }
  }

  .footer-legal {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    margin: 20px 0 0;

    @include mobile {
      flex-direction: column;
    }

    .copyright {
      margin-right: 20%;

      @include mobile {
        order: 2;
        margin: 20px 0 0;
      }
    }

    .legal-menu {

      ul {
        display: flex;

        li {
          margin-right: 40px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
